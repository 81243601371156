import {
  JOIN_LINK_VIEW_TYPE,
  MISSED_ENTRY_START_VISIT_VIEW_TYPE,
  START_VISIT_VIEW_TYPE,
} from '@m/constants/QRTypes';

import type {
  JoinLinkQRCodeResp,
  QRCodeResp,
  MissedEntryStartVisitQRCodeResp,
  StartVisitQRCodeResp,
} from '@m/types/api';

export function isStartVisitQR(qrResp: QRCodeResp): qrResp is StartVisitQRCodeResp {
  return qrResp.type === START_VISIT_VIEW_TYPE;
}

export function isJoinLinkQR(qrResp: QRCodeResp): qrResp is JoinLinkQRCodeResp {
  return qrResp.type === JOIN_LINK_VIEW_TYPE;
}

export function isMissedEntryStartVisitQR(
  qrResp: QRCodeResp,
): qrResp is MissedEntryStartVisitQRCodeResp {
  return qrResp.type === MISSED_ENTRY_START_VISIT_VIEW_TYPE;
}
