import type { HTMLAttributes } from 'react';

import withStyledSystem from '@m/theme/helpers/withStyledSystem';
import styled from 'styled-components';

import type { StyledSystemProps } from '@m/theme/helpers/withStyledSystem';

export type BoxProps = HTMLAttributes<HTMLDivElement> & StyledSystemProps;

/**
 * @deprecated Use Chakra UI Box/Flex instead in @chakra-ui/react
 */
export const Box = styled.div<StyledSystemProps>`
  ${withStyledSystem}
`;
Box.defaultProps = {
  position: 'relative',
  display: 'flex',
  flex: 1,
  lineHeight: 1,
};
