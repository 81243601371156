import type { MouseEventHandler } from 'react';

import { ButtonBase, ButtonPrimary, ButtonSecondary, ButtonDestructive } from './Button.styled';

type ButtonProps = {
  htmlType?: 'button' | 'submit' | 'reset';
  variant?: 'primary' | 'secondary' | 'destructive';
  onPress?: MouseEventHandler<HTMLButtonElement>;
};

/**
 * @deprecated Use Chakra UI Button instead in @m/components/ui/button
 */
export function Button({
  variant,
  onPress,
  htmlType = 'button',
  ...passProps
}: ButtonProps & React.ComponentProps<typeof ButtonComponent>) {
  let ButtonComponent = ButtonBase;
  if (variant === 'primary') {
    ButtonComponent = ButtonPrimary;
  } else if (variant === 'secondary') {
    ButtonComponent = ButtonSecondary;
  } else if (variant === 'destructive') {
    ButtonComponent = ButtonDestructive;
  }

  return (
    <ButtonComponent
      data-testid={`button-${variant}`}
      onClick={onPress}
      type={htmlType}
      {...passProps}
    />
  );
}
